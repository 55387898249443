
import { defineComponent, onBeforeUnmount, onMounted, PropType, ref, toRefs, watch } from 'vue'
import { MenuOptions, MenuItem, ContextMenuGlobalData, ContextMenuPositionData, MenuConstOptions } from './ContextMenuDefine'

export default defineComponent({
  name: 'ContextSubMenu',
  props: {
    items: { 
      type: Object as PropType<Array<MenuItem>>,
      default: null
    },
    parentItem: {
      type: Object as PropType<MenuItem>,
      default: null
    },
    options: { 
      type: Object as PropType<MenuOptions>,
      default: null
    },
    zIndex: {
      type: Number,
      default: 0
    },
    globalData: {
      type: Object as PropType<ContextMenuGlobalData>,
      default: null
    },
    position: {
      type: Object as PropType<ContextMenuPositionData>,
      default: null
    }
  },
  emits: [ 'close', 'keepOpen', 'preUpdatePos' ],
  setup(prop, context) {

    const { globalData, position, options, parentItem } = toRefs(prop);

    const menu = ref<HTMLElement>();
    const childMenu = ref();
    const menuReady = ref(false);
    const menuOverflow = ref(false);

    let nextShouldHideItem = null as MenuItem|null;
    const maxHeight = ref(0);
    const activeItem = ref<MenuItem|null>(null);
    const childGlobalData = ref({
      parentPosition: { x: 0, y: 0 },
      screenSize: globalData.value.screenSize,
    } as ContextMenuGlobalData);
    const childPosition = ref<ContextMenuPositionData>({
      x: 0,
      y: 0
    });

    //显示和隐藏子菜单
    function showChildItem(e: Event, item : MenuItem) {
      if(item.disabled || !item.children || item.children.length == 0) 
        return;
      if(activeItem.value === item)
        return;
      
      //同步父菜单的位置
      activeItem.value = item;
      childGlobalData.value.parentPosition.x = globalData.value.parentPosition.x + position.value.x;
      childGlobalData.value.parentPosition.y = globalData.value.parentPosition.y + position.value.y;

      //计算子菜单的位置
      if(menu.value) 
        childPosition.value.x = menu.value.offsetWidth + (options.value.xOffset || 0);
      const currentItemEle = e.target as HTMLElement;
      if(currentItemEle) 
        childPosition.value.y = currentItemEle.offsetTop + (options.value.yOffset || 0);
    }
    function hideChildItem() {
      nextShouldHideItem = activeItem.value;
      setTimeout(() => {
        if(nextShouldHideItem === activeItem.value)
          activeItem.value = null;
      });
    }

    watch(activeItem, (newV : MenuItem|null, oldV : MenuItem|null) => {
      if(newV && oldV) {
        setTimeout(() => {
          if(childMenu.value) childMenu.value.doCheckPos()
        }, 50)
      }
    });

    //子菜单事件
    function onChildrenClose(byUserClick : boolean) {
      hideChildItem();
      if(byUserClick)
        context.emit('close', true);
    }
    function onChildrenKeepOpen(item : MenuItem) {
      if(nextShouldHideItem === item)
        nextShouldHideItem = null; 
      context.emit('keepOpen', parentItem.value);
    }
    function onChildrenUpdatePos(newPos: ContextMenuPositionData) {
      childPosition.value.x = newPos.x;
      childPosition.value.y = newPos.y;
    }
    //鼠标事件
    function onMouseClick(item : MenuItem) {
      if(!item.disabled) {
        if(typeof item.onClick === 'function') {
          item.onClick();
          context.emit('close', true);
        }
        else if(!item.children || item.children.length === 0) {
          context.emit('close', true);
        }
      }
    }
    function onMenuMouseEnter() {
      context.emit('keepOpen', parentItem.value);
    }
    function onMenuMouseLeave(e : MouseEvent) {
      if(e.relatedTarget != null)
        context.emit('close', false);
    }



    //滚动
    function onScroll(down : boolean) {
      if(menu.value) {
        menu.value.scrollTop += down ? 30 : -30;
      }
    }

    let solveOverflowTimeOut = 0;

    //检查菜单是否超出屏幕
    function doCheckPos() {
      const _menu = menu.value;
      const _globalData = globalData.value;
      if(_menu) {

        const newPos = {
          x: position.value.x,
          y: position.value.y
        } as ContextMenuPositionData;

        //如果X绝对位置超出屏幕，那么减去超出的宽度
        const absRight = _globalData.parentPosition.x + position.value.x + _menu.offsetWidth;
        if(absRight > _globalData.screenSize.w) {
          newPos.x -= absRight - _globalData.screenSize.w;
        }

        //如果高度超出屏幕，那么限制最高高度
        if(_menu.offsetHeight > _globalData.screenSize.h - 30) {
          maxHeight.value = _globalData.screenSize.h - 30;
          //  强制限制Y坐标为0
          newPos.y = -_globalData.parentPosition.y;
          menuOverflow.value = true;
        }
        else {
          menuOverflow.value = false;
          maxHeight.value = 0;
          //如果Y绝对位置超出屏幕，那么减去超出的高度
          const absTop = _globalData.parentPosition.y + position.value.y + _menu.offsetHeight;
          if(absTop >_globalData.screenSize.h) {
            newPos.y -= absTop - _globalData.screenSize.h + 30;
          }   
        }

        context.emit('preUpdatePos', newPos);
        menuReady.value = true;
      }
    }

    onMounted(() => {
      solveOverflowTimeOut = window.setTimeout(() => doCheckPos(), 100)
    })
    onBeforeUnmount(() => {
      if(solveOverflowTimeOut > 0) {
        clearTimeout(solveOverflowTimeOut);
        solveOverflowTimeOut = 0;
      }
    })

    return {
      menu,
      menuOverflow,
      childMenu,
      menuReady,
      maxHeight,
      showChildItem,
      hideChildItem,
      onChildrenClose,
      onChildrenKeepOpen,
      onChildrenUpdatePos,
      onMenuMouseLeave,
      onMenuMouseEnter,
      onMouseClick,
      onScroll,
      doCheckPos,
      activeItem,
      childGlobalData,
      childPosition,
      constOptions: MenuConstOptions,
    }
  }
})
